import { UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiAlertModule } from "@quantive/ui-kit/alert";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { UiTabModule } from "@quantive/ui-kit/tab";
import { Observable, forkJoin, map, mergeMap, of, take } from "rxjs";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { humanError } from "@gtmhub/error-handling/util";
import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { EditionFeatureDirective } from "@webapp/accounts/directives/edition-feature.directive";
import { AnalyticsModule } from "@webapp/analytics/analytics.module";
import { TrackingMetadata } from "@webapp/analytics/models/analytics.model";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { ICollection } from "@webapp/core/core.models";
import { FileserverModule } from "@webapp/fileserver/fileserver.module";
import { FileserverFacade } from "@webapp/fileserver/services/fileserver-facade.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { BillingInfoService } from "@webapp/navigation/services/billing-info.service";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { Whiteboard } from "@webapp/whiteboards/models/whiteboard.models";
import { WhiteboardsRepository } from "@webapp/whiteboards/services/whiteboards-repository.service";
import { WhiteboardsUserSettingsService } from "@webapp/whiteboards/services/whiteboards-user-settings.service";
import { ContainerEmptyStateComponent } from "../../../../shared/components/container-empty-state/container-empty-state.component";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";
import { WidgetSkeletonComponent } from "../widget-skeleton/widget-skeleton.component";
import { byIndexOfArray, byOpenedAtDesc } from "./whiteboards-widget.utils";

const MAX_WHITEBOARDS = 4;
const WHITEBOARD_FIELDS = ["access", "ownerId", "name", "picture", "createdAt", "createdById", "lastModifiedAt"] as const;

type CustomWhiteboard = Pick<Whiteboard, (typeof WHITEBOARD_FIELDS)[number]> & { id: string };

@UntilDestroy()
@Component({
  selector: "whiteboards-widget",
  templateUrl: "./whiteboards-widget.component.html",
  styleUrls: ["./whiteboards-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UiCardModule,
    LocalizationModule,
    UiTabModule,
    UiLoadingIndicatorModule,
    UiAlertModule,
    FileserverModule,
    AssigneesModule,
    WidgetActionsComponent,
    UiAccessibilityModule,
    WidgetSkeletonComponent,
    ContainerEmptyStateComponent,
    EditionFeatureDirective,
    UiButtonModule,
    UIRouterModule,
    AnalyticsModule,
  ],
})
export class WhiteboardsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public error: string;
  public loading = false;
  public whiteboards: CustomWhiteboard[] = [];
  private activeTabIndex = 0;
  private currentUserId: string;

  public get title(): string {
    return localize("whiteboards");
  }

  public get a11yLabel(): string {
    return "Whiteboards";
  }

  public get emptyStateMessage(): string {
    return this.activeTabIndex === 0 ? localize("recently_viewed_whiteboards_will_show_here") : localize("shared_whiteboards_will_show_here");
  }

  public get showUpgradeButton$(): Observable<boolean> {
    return this.billingInfoService.getUpgradeShouldBeShown$();
  }

  constructor(
    private whiteboardsRepository: WhiteboardsRepository,
    private fileserverFacade: FileserverFacade,
    private cdr: ChangeDetectorRef,
    private whiteboardsUserSettingsService: WhiteboardsUserSettingsService,
    private billingInfoService: BillingInfoService,
    private analyticsService: AnalyticsService,
    private apmService: ApmService
  ) {
    super();
    this.apmService.startDataLoadSpan("whiteboards-widget-init");
  }

  public ngOnInit(): void {
    this.currentUserId = getCurrentUserId();
    this.getRecentlyOpenedWhiteboards();
  }

  public changeActiveTabIndex(index: number): void {
    if (index === this.activeTabIndex) {
      return;
    }

    this.activeTabIndex = index;
    if (index === 1) {
      this.getSharedWhiteboards();
    } else {
      this.getRecentlyOpenedWhiteboards();
    }

    this.analyticsService.track("Tab Clicked", {
      name: "home_customizable",
      widget: "whiteboards",
      tab: index === 1 ? "shared_with_me" : "recently_viewed",
      user_id: this.currentUserId,
    });
  }

  public getWhiteboardClickedMetaData(whiteboardId: string): TrackingMetadata {
    return {
      name: "home_customizable",
      widget: "whiteboards",
      tab: this.activeTabIndex === 1 ? "shared_with_me" : "recently_viewed",
      user_id: this.currentUserId,
      whiteboard_id: whiteboardId,
    };
  }

  private getRecentlyOpenedWhiteboardIds(): string[] {
    return this.whiteboardsUserSettingsService
      .getOpened()
      .sort(byOpenedAtDesc)
      .slice(0, MAX_WHITEBOARDS)
      .map((x) => x.id);
  }

  private getRecentlyOpenedWhiteboards(): void {
    const recentlyOpenedWhiteboardIds = this.getRecentlyOpenedWhiteboardIds();

    const whiteboards$ = recentlyOpenedWhiteboardIds.length
      ? this.whiteboardsRepository
          .getAll$<ICollection<CustomWhiteboard>>({
            filter: {
              take: MAX_WHITEBOARDS,
              fields: [...WHITEBOARD_FIELDS],
              filter: { _id: { $in: recentlyOpenedWhiteboardIds } },
            },
          })
          .pipe(map((col) => col.items.sort(byIndexOfArray(recentlyOpenedWhiteboardIds))))
      : of<CustomWhiteboard[]>([]);

    this.getWhiteboards(whiteboards$);
  }

  private getSharedWhiteboards(): void {
    const whiteboards$ = this.whiteboardsRepository
      .getAll$<ICollection<CustomWhiteboard>>({
        filter: {
          take: MAX_WHITEBOARDS,
          fields: [...WHITEBOARD_FIELDS],
          filter: { ownerId: { $ne: this.currentUserId } },
          sort: ["-lastModifiedAt", "-createdAt"],
        },
      })
      .pipe(map((col) => col.items));
    this.getWhiteboards(whiteboards$);
  }

  private getWhiteboards(whiteboards$: Observable<CustomWhiteboard[]>): void {
    this.loading = true;
    this.error = null;
    this.cdr.markForCheck();

    whiteboards$
      .pipe(
        take(1),
        untilDestroyed(this),
        mergeMap((whiteboards) =>
          whiteboards.length
            ? forkJoin(
                whiteboards.map((item) =>
                  (item.picture ? this.fileserverFacade.generateFileUrl$(item.picture) : of(null)).pipe(
                    map<string, CustomWhiteboard>((picture) => ({ ...item, picture }))
                  )
                )
              )
            : of<CustomWhiteboard[]>([])
        )
      )
      .subscribe({
        next: (whiteboards) => {
          this.whiteboards = whiteboards;
          this.loading = false;
          this.cdr.markForCheck();
          this.apmService.endDataLoadSpan("whiteboards-widget-init");
        },
        error: (err) => {
          this.error = humanError(err);
          this.loading = false;
          this.cdr.markForCheck();
          this.apmService.endDataLoadSpan("whiteboards-widget-init");
        },
      });
  }
}
