import { StateService } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAvatarComponent } from "@quantive/ui-kit/avatar";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { AssigneeActions, IAssigneesStoreState } from "@gtmhub/assignees";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeam, ITeamsStoreState, TeamsActions } from "@gtmhub/teams";
import { IdMap } from "@gtmhub/util";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { Employee } from "@webapp/employees";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiPopoverModule } from "@webapp/ui/popover/popover.module";

const getEmployeeTeams = (employee: Employee, teams: ITeam[]): ITeam[] => {
  if (!employee || !teams?.length) {
    return [];
  }

  return teams.filter((team) => team.members?.includes(employee.id));
};

interface AssigneeDetails {
  id?: string;
  isActive?: boolean;
  email?: string;
  dateCreated?: string;
  manager?: string;
}
type AssigneeType = "user" | "team";

@UntilDestroy()
@Component({
  selector: "assignee-details-popover",
  templateUrl: "assignee-details-popover.component.html",
  styleUrls: ["assignee-details-popover.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiPopoverModule, UiIconModule, UiAvatarComponent, LocalizationModule, AssigneesModule, UiButtonComponent],
})
export class AssigneeDetailsPopoverComponent implements OnInit {
  @Input({ required: true })
  public assigneeId: string;
  @Input({ required: true })
  public assigneeType: AssigneeType;
  @Input()
  public enabled = true;

  public isOpen = false;
  public assignee: AssigneeDetails;
  public list: Employee[] | ITeam[];
  public listTotalCount: number;
  public assignees: IdMap<Assignee>;
  public teams: IdMap<ITeam>;
  public limit = 3;

  constructor(
    private teamActions: TeamsActions,
    private assigneeActions: AssigneeActions,
    private stateService: StateService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (!this.assigneeType) {
      return;
    }

    this.loadRedux();
  }

  public navigateToProfile(): void {
    if (!this.assigneeType) {
      return;
    }

    const goToUrl = this.assigneeType === "user" ? "gtmhub.employees.employee" : "gtmhub.teams.team";
    const goToUrlParamProp = this.assigneeType === "user" ? "employeeId" : "teamId";

    this.stateService.go(goToUrl, { [goToUrlParamProp]: this.assigneeId });
    this.close();
  }

  public close(): void {
    this.isOpen = false;
    this.cdr.detectChanges();
  }

  private loadRedux(): void {
    const { reduxStore } = reduxStoreContainer;
    const reduxObserver = new ReduxStoreObserver(reduxStore);
    reduxStore.dispatch(this.assigneeActions.getAssignees());
    reduxStore.dispatch(this.teamActions.fetchTeamsIfMissing());

    reduxObserver
      .whenFetched$<IAssigneesStoreState & ITeamsStoreState>("assignees", "teams")
      .pipe(untilDestroyed(this))
      .subscribe((storeData) => {
        if (!storeData.teams.isFetched || !storeData.assignees.isFetched || (this.assignees === storeData.assignees.map && this.teams === storeData.teams.map)) {
          return;
        }

        let maps = {
          assignees: storeData.assignees.map,
          teams: storeData.teams.map,
          assignee: {},
          list: [],
        };

        if (this.assigneeType === "user") {
          maps = {
            ...maps,
            assignee: storeData.assignees.map[this.assigneeId],
            list: getEmployeeTeams(storeData.assignees.map[this.assigneeId], storeData.teams.items),
          };
        }

        if (this.assigneeType === "team") {
          maps = {
            ...maps,
            assignee: { ...storeData.assignees.map[this.assigneeId], ...storeData.teams.map[this.assigneeId] },
            list: (storeData.teams.map[this.assigneeId]?.members || []).map((memberId) => storeData.assignees.map[memberId]),
          };
        }

        this.assignees = maps.assignees;
        this.teams = maps.teams;
        this.assignee = this.toAssigneeDetails(maps.assignee);
        this.list = maps.list?.slice(0, this.limit) ?? [];
        this.listTotalCount = maps.list?.length ?? 0;
        this.cdr.detectChanges();
      });
  }

  private toAssigneeDetails(assignee: ITeam | Employee): AssigneeDetails {
    const assigneeDetails: AssigneeDetails = {};
    assigneeDetails.id = assignee.id;
    assigneeDetails.isActive = assignee.isActive;

    if ((assignee as Employee).email) {
      assigneeDetails.email = (assignee as Employee).email;
    }

    if ((assignee as Employee).dateCreated) {
      assigneeDetails.dateCreated = (assignee as Employee).dateCreated;
    }

    if ((assignee as ITeam).manager) {
      assigneeDetails.manager = (assignee as ITeam).manager;
    }

    return assigneeDetails;
  }
}
