import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, TemplateRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAvatarModule, UiAvatarSize } from "@quantive/ui-kit/avatar";
import { UiAssigneeI18nInterface, UiI18nService } from "@quantive/ui-kit/i18n";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { UiTooltipModule, UiTooltipPlacement } from "@quantive/ui-kit/tooltip";
import { InputBoolean } from "ng-zorro-antd/core/util";
import defaultAvatarUrl from "wwwroot/img/avatars/default_avatar.png";
import { Assignee, MissingAssignee, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { isMissingAssignee, isUnknownAssignee } from "@webapp/assignees/utils/assignee.utils";
import { UiAssigneeNameComponent } from "../assignee-name/assignee-name.component";

const DEFAULT_AVATAR_ICON = "travel_world";
const DEFAULT_BG_COLOR = "#87a6bc";

/**
 * Uses avatar to display an assignee. Assignees can be users, teams, or
 * a deleted assignee (i.e. we know only their ID, but no information about who they were).
 */
@UntilDestroy()
@Component({
  selector: "ui-assignee-avatar",
  exportAs: "uiAssigneeAvatar",
  templateUrl: "assignee-avatar.component.html",
  styleUrls: ["./assignee-avatar.component.less"],
  host: {
    "[class.ui-assignee-avatar-x-small]": `uiSize === 'xs'`,
    "[class.ui-assignee-avatar-small]": `uiSize === 'sm'`,
    "[class.ui-assignee-avatar-medium]": `uiSize === 'md'`,
    "[class.ui-assignee-avatar-large]": `uiSize === 'lg'`,
    "[class.ui-assignee-avatar-x-large]": `uiSize === 'xl'`,
    "[class.ui-assignee-avatar-xx-large]": `uiSize === 'xxl'`,
    "[attr.data-test-id]": `"assignee-avatar-" + assigneeName`,
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiAssigneeNameComponent, UiAvatarModule, UiTooltipModule, NgIf],
})
export class UiAssigneeAvatarComponent implements OnChanges {
  @Input()
  public uiAssignee: Assignee | UnknownAssignee | MissingAssignee;

  @Input()
  public uiSize: UiAvatarSize = "md";

  @Input()
  @InputBoolean()
  public uiShowTooltip?: boolean;

  @Input()
  public uiTooltipPlacement?: UiTooltipPlacement;

  @Input()
  public uiTooltipTitle?: string | TemplateRef<void>;

  @Input()
  public uiAlt?: string;

  @Input()
  @InputBoolean()
  public hasBorder?: boolean;

  @Input()
  public borderColor?: string;

  /**
   * @internal
   */
  public picture?: string;

  /**
   * @internal
   */
  public icon?: string;

  /**
   * @internal
   */
  public backgroundColor = DEFAULT_BG_COLOR;

  /**
   * @internal
   */
  public disabled?: boolean;

  /**
   * @internal
   */
  public theme?: UiThemeType = "outline";

  /**
   * @internal
   */
  public isUnknown?: boolean = false;

  /**
   * @internal
   */
  public isMissing?: boolean = false;

  /**
   * @internal
   */
  public assigneeName?: string;

  /**
   * @internal
   */
  public realAlt: string;

  /**
   * @internal
   */
  public onError: () => void;

  private locale: UiAssigneeI18nInterface;

  constructor(i18n: UiI18nService) {
    this.onError = (): void => {
      this.picture = defaultAvatarUrl;
      delete this.icon;
      delete this.theme;
      delete this.backgroundColor;
    };

    i18n.localeChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.locale = i18n.getLocaleData("Assignee");
    });
  }

  public ngOnChanges(): void {
    let defaultAlt: string;
    if (this.uiAssignee) {
      if (isUnknownAssignee(this.uiAssignee)) {
        this.configureAvatarFromUnknownAssignee(this.uiAssignee);
        defaultAlt = this.locale.deletedUser;
      } else if (isMissingAssignee(this.uiAssignee)) {
        this.configureAvatarFromMissingAssignee(this.uiAssignee);
        defaultAlt = this.locale.unknown;
      } else {
        this.configureAvatarFromAssignee(this.uiAssignee);
        defaultAlt = this.assigneeName;
      }
    }

    this.realAlt = this.uiAlt == null ? defaultAlt : this.uiAlt;
  }

  private configureAvatarFromAssignee(assignee: Assignee): void {
    this.assigneeName = (assignee?.name || "").trim();
    this.disabled = assignee.isActive === false;
    this.isUnknown = false;

    if (assignee.picture) {
      this.configureAvatarFromAssigneeWithPicture(assignee);
    } else {
      this.configureAvatarFromAssigneeWithIcon(assignee);
    }
  }

  private configureAvatarFromAssigneeWithPicture(assignee: Assignee): void {
    this.picture = assignee.picture;
    delete this.icon;
    delete this.theme;
    delete this.backgroundColor;
  }

  private configureAvatarFromAssigneeWithIcon(assignee: Assignee): void {
    this.icon = (assignee.type === "team" && assignee.avatar) || DEFAULT_AVATAR_ICON;
    this.theme = "fill";
    this.backgroundColor = (assignee.type === "team" && assignee.color) || DEFAULT_BG_COLOR;
    delete this.picture;
  }

  private configureAvatarFromUnknownAssignee(assignee: UnknownAssignee): void {
    this.icon = assignee.avatar;
    this.theme = "outline";
    this.isUnknown = true;
    this.backgroundColor = assignee.color;
  }

  private configureAvatarFromMissingAssignee(assignee: MissingAssignee): void {
    this.icon = assignee.avatar;
    this.theme = "outline";
    this.isMissing = true;
    this.backgroundColor = assignee.color;
  }
}
