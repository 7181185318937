import { module } from "angular";
import { UiAlertComponent } from "@quantive/ui-kit/alert";
import { UiAvatarComponent } from "@quantive/ui-kit/avatar";
import { UiButtonSplitComponent } from "@quantive/ui-kit/button";
import { UiCardComponent } from "@quantive/ui-kit/card";
import { UiCheckboxComponent } from "@quantive/ui-kit/checkbox";
import { UiLabelComponent } from "@quantive/ui-kit/label";
import { UiSkeletonComponent } from "@quantive/ui-kit/skeleton";
import { UiTagComponent } from "@quantive/ui-kit/tag";
import { UiToggleComponent } from "@quantive/ui-kit/toggle";
import comments from "@gtmhub/comments/module";
import { FilterFormCtrl } from "@gtmhub/hub/controllers/filter-form";
import localization from "@gtmhub/localization/module";
import searchCore from "@gtmhub/search/search-core.module";
import search from "@gtmhub/search/search.module";
import { IndicatorCD } from "@gtmhub/shared/factories/gh-indicator-cd";
import sharedFilters from "@gtmhub/shared/filters/filters.module";
import ngExtensions from "@gtmhub/shared/ng-extensions/ng-extensions.module";
import teamsCore from "@gtmhub/teams/teams-core.module";
import uiBootstrap from "@gtmhub/ui-bootstrap.module";
import { WelcomeModalV1Component } from "@gtmhub/welcome-modal/components/welcome-modal-v1/welcome-modal-v1";
import { CommentsFacade, ReactionsFacade } from "@webapp/comments";
import { FavoriteButtonComponent } from "@webapp/favorites/favorite-button/favorite-button.component";
import { UserDetailsComponent } from "@webapp/home/components/user-details/user-details.component";
import { FeaturedInsightboardsComponent } from "@webapp/insightboards/components/featured-insightboards/featured-insightboards.component";
import { MetricProgressBarComponent } from "@webapp/okrs/metrics/components/metric-progress-bar/metric-progress-bar.component";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { SessionSelectorUtilsService } from "@webapp/sessions/services/session-selector-utils.service";
import { AssigneeAvatarGroupComponent } from "@webapp/shared/components/assignee-avatar-group/assignee-avatar-group.component";
import { BadgeAvatarComponent } from "@webapp/shared/components/badge-avatar/badge-avatar.component";
import { ButtonComponent } from "@webapp/shared/components/button/button.component";
import { CreateOkrDropdownButtonComponent } from "@webapp/shared/components/create-okr-dropdown-button/create-okr-dropdown-button.component";
import { DatePickerLegacyComponent } from "@webapp/shared/components/date-picker-legacy/date-picker-legacy.component";
import { EmptyFilterResultsComponent } from "@webapp/shared/components/empty-filter-results/empty-filter-results.component";
import { GifViewComponent } from "@webapp/shared/components/gifs/gif-view/gif-view.component";
import { TenorGifService } from "@webapp/shared/components/gifs/tenor-gif.service";
import { IconComponent } from "@webapp/shared/components/icon/icon.component";
import { InputSearchComponent } from "@webapp/shared/components/input-search/input-search.component";
import { ItemAutomationSelectorComponent } from "@webapp/shared/components/item-automation-selector/components/item-automation-selector/item-automation-selector.component";
import { SupportedItemAutomationSuggestionTypeService } from "@webapp/shared/components/item-automation-selector/providers/supported-automation-suggestion-type.service";
import { SupportedItemAutomationTypeService } from "@webapp/shared/components/item-automation-selector/providers/supported-automation-type.service";
import { ItemAutomationComponent } from "@webapp/shared/components/item-automation/item-automation.component";
import { NothingFoundComponent } from "@webapp/shared/components/nothing-found/nothing-found.component";
import { OnboardingComponent } from "@webapp/shared/components/onboarding/onboarding.component";
import { PeopleSelectorComponent } from "@webapp/shared/components/people-selector/people-selector.component";
import { PlatformRedirectComponent } from "@webapp/shared/components/platform-redirect/platform-redirect.component";
import { DateViewComponent } from "@webapp/shared/date-view/date-view.component";
import { DropdownComponent } from "@webapp/shared/dropdown/dropdown.component";
import { EmptyStateViewInfoBoxesComponent } from "@webapp/shared/empty-state/empty-state-info-boxes/empty-state-info-boxes.component";
import { EmptyStateViewComponent } from "@webapp/shared/empty-state/empty-state.component";
import { ErrorNotificationComponent } from "@webapp/shared/error-notification/error-notification.component";
import { ErrorViewComponent } from "@webapp/shared/error-view/error-view.component";
import { FeatureBannerComponent } from "@webapp/shared/feature-banner/feature-banner.component";
import { IconWithPopoverComponent } from "@webapp/shared/icon-with-popover/icon-with-popover.component";
import { IndicatorComponent } from "@webapp/shared/indicator/indicator.component";
import { PaginationComponent } from "@webapp/shared/pagination/pagination.component";
import { RichTextEditorComponent } from "@webapp/shared/rich-text-editor/rich-text-editor.component";
import { RichTextViewerComponent } from "@webapp/shared/rich-text-editor/rich-text-viewer/rich-text-viewer.component";
import { SkipLinkComponent } from "@webapp/shared/skip-link/skip-link.component";
import { SaveFilterChangesComponent } from "@webapp/shared/unified-filters/components/save-filter-changes/save-filter-changes.component";
import { ShareableFiltersModalsService } from "@webapp/shared/unified-filters/services/shareable-filters-modals.service";
import { ShareableFiltersFacade } from "@webapp/shared/unified-filters/services/shareable-filters/shareable-filters-facade.service";
import { UnitSelectorComponent } from "@webapp/shared/unit-selector/unit-selector.component";
import { UiAssigneeComponent } from "@webapp/ui/assignee/assignee.component";
import { UiAssigneeAvatarComponent } from "@webapp/ui/assignee/components/assignee-avatar/assignee-avatar.component";
import { UiAssigneeNameComponent } from "@webapp/ui/assignee/components/assignee-name/assignee-name.component";
import { UiDatePickerComponent } from "@webapp/ui/date-picker/date-picker.component";
import { UiInputTextFieldComponent } from "@webapp/ui/input/components/input-text-field/input-text-field.component";
import { UiValidationHintComponent } from "@webapp/ui/input/components/validation-hint/validation-hint.component";
import { UiRadioGroupComponent } from "@webapp/ui/radio/components/radio-group/radio-group.component";
import { UiRadioComponent } from "@webapp/ui/radio/radio.component";
import { UiTimePickerComponent } from "@webapp/ui/time-picker/time-picker.component";
import { DateRangePickerCtrl } from "../controllers/daterange-picker";
import { Permissions } from "../controllers/permissions-directive";
import { UnitFormattingFormCtrl } from "../controllers/unit-formatting";
import { ArraySelectionDirective } from "../directives/array-selection";
import { ClickOnFirstInput } from "../directives/click-on-first-input";
import { GhCompileDirective } from "../directives/compile";
import { DropdownKeys } from "../directives/dropdown-keys";
import { FocusMe, FocusOn } from "../directives/focus";
import { GtmhubDropdown, GtmhubDropdownItem } from "../directives/gtmhub-dropdown";
import { CmdEnterSubmitDirective, KeydownDirective } from "../directives/keydown";
import { MarketplaceLinkDirective } from "../directives/marketplace-link";
import { RerenderDetectorDirective } from "../directives/rerender-detector";
import { ToggleDropdown } from "../directives/toggle-dropdown";
import { TabAction } from "./behaviours/tab-action";
import dndList from "./dnd-list/dnd-list.module";
import { EditableTextareaDirective } from "./editable-textarea/editable-textarea";
import { FeatureNotAvailableComponent } from "./feature-not-available/feature-not-available.component";
import { FullNameComponent } from "./full-name/full-name.component";
import { GenericSessionSelectorComponent } from "./generic-session-selector/generic-session-selector.component";
import grid from "./grid/grid.module";
import { HowToStartComponent } from "./how-to-start/how-to-start.component";
import { IncludedInPermissionComponent } from "./included-in-permission/included-in-permission.component";
import { MenuDirective, MenuItemDirective } from "./menu/menu.directive";
import ngTagsInput from "./ng-tags-input";
import { NothingFoundComponent as Ng1NothingFoundComponent } from "./nothing-found/nothing-found.component";
import { PermissionPrincipalComponent } from "./permissions/permission-principal.component";
import { RolesSelectorComponent } from "./roles-selector/roles-selector.components";
import { SessionSelectorDropdownComponent } from "./session-selector-dropdown/session-selector-dropdown.component";
import { SessionTreeSelectorComponent } from "./session-tree-selector/session-tree-selector.component";
import { SubteamsTreeComponent } from "./subteams-tree/subteams-tree.component";
import { TagsAutocompleteInputComponent } from "./tags-autocomplete-input/tags-autocomplete-input.component";
import { TreeSelectorComponent } from "./tree-selector/tree-selector.component";
import { Button } from "./ui/buttons/gh-button/gh-button";
import { DropdownButton } from "./ui/buttons/gh-dropdown-button/gh-dropdown-button";
import { DropdownButtonMenuItem } from "./ui/buttons/gh-dropdown-button/gh-dropdown-button-menu-item";
import { DropdownActionsMenuComponent } from "./ui/dropdown-actions-menu/dropdown-actions-menu.component";
import { SnackbarComponent } from "./ui/snackbar/snackbar.component";
import { UserSelectorComponent } from "./user-selector/user-selector.component";

const mod = module("sharedComponents", [sharedFilters, uiBootstrap, ngExtensions, ngTagsInput, localization, grid, dndList, search, comments, teamsCore, searchCore])
  .component("snackbar", SnackbarComponent)
  .component("dropdownActionsMenu", DropdownActionsMenuComponent)
  .component("userSelector", UserSelectorComponent)
  .component("howToStart", HowToStartComponent)
  .downgradeComponent("emptyFilterResults", { component: EmptyFilterResultsComponent, propagateDigest: false })
  .component("nothingFound", Ng1NothingFoundComponent)
  .component("treeSelector", TreeSelectorComponent)
  .component("sessionTreeSelector", SessionTreeSelectorComponent)
  .component("fullName", FullNameComponent)
  // to do: move to permissions module - https://gtmhub.atlassian.net/browse/GVS-13832
  .component("subteamsTree", SubteamsTreeComponent)
  .downgradeComponent("gifView", { component: GifViewComponent, propagateDigest: false })
  .component("permissionPrincipal", PermissionPrincipalComponent)
  .component("sessionSelectorDropdown", SessionSelectorDropdownComponent)
  .component("featureNotAvailable", FeatureNotAvailableComponent)
  .component("rolesSelector", RolesSelectorComponent)
  // to do: move to permissions module - https://gtmhub.atlassian.net/browse/GVS-13832
  .component("includedInPermission", IncludedInPermissionComponent)
  .component("tagsAutocompleteInput", TagsAutocompleteInputComponent)
  .component("genericSessionSelector", GenericSessionSelectorComponent)
  // to do: move to permissions module - https://gtmhub.atlassian.net/browse/GVS-13832
  .directive("permissions", Permissions.factory())
  .downgradeComponent("richTextEditor", { component: RichTextEditorComponent, propagateDigest: false })
  .downgradeComponent("richTextViewer", { component: RichTextViewerComponent, propagateDigest: false })
  .directive("ghButton", Button.factory())
  .directive("ghDropdownButton", DropdownButton.factory())
  .directive("ghDropdownButtonMenuItem", DropdownButtonMenuItem.factory())
  .directive("editableTextarea", EditableTextareaDirective.factory())
  .directive("tabAction", TabAction.factory())
  .downgradeComponent("ghIcon", { component: IconComponent, propagateDigest: false })
  .downgradeComponent("uiButton", { component: ButtonComponent, propagateDigest: false })
  .downgradeComponent("uiToggle", { component: UiToggleComponent, propagateDigest: false })
  .downgradeComponent("uiDatePicker", { component: UiDatePickerComponent, propagateDigest: false })
  .downgradeComponent("uiTimePicker", { component: UiTimePickerComponent, propagateDigest: false })
  .downgradeComponent("uiInputTextField", { component: UiInputTextFieldComponent, propagateDigest: false })
  .downgradeComponent("uiButtonSplit", { component: UiButtonSplitComponent, propagateDigest: false })
  .downgradeComponent("uiRadio", { component: UiRadioComponent, propagateDigest: false })
  .downgradeComponent("uiRadioGroup", { component: UiRadioGroupComponent, propagateDigest: false })
  .downgradeComponent("uiCheckbox", { component: UiCheckboxComponent, propagateDigest: false })
  .downgradeComponent("uiAvatar", { component: UiAvatarComponent, propagateDigest: false })
  .downgradeComponent("uiAssigneeAvatar", { component: UiAssigneeAvatarComponent, propagateDigest: false })
  .downgradeComponent("uiAssignee", { component: UiAssigneeComponent, propagateDigest: false })
  .downgradeComponent("uiTag", { component: UiTagComponent, propagateDigest: false })
  .downgradeComponent("iconWithPopover", { component: IconWithPopoverComponent, propagateDigest: false })
  .downgradeComponent("uiAssigneeName", { component: UiAssigneeNameComponent, propagateDigest: false })
  .downgradeComponent("unitSelector", { component: UnitSelectorComponent, propagateDigest: false })
  .downgradeComponent("uiSkeleton", { component: UiSkeletonComponent, propagateDigest: false })
  .downgradeComponent("errorView", { component: ErrorViewComponent, propagateDigest: false })
  .downgradeComponent("ghIndicator", { component: IndicatorComponent, propagateDigest: false })
  .downgradeComponent("ghNothingFound", { component: NothingFoundComponent, propagateDigest: false })
  .downgradeComponent("ghDropdown", { component: DropdownComponent, propagateDigest: false })
  .downgradeComponent("skipLink", { component: SkipLinkComponent, propagateDigest: false })
  .directive("ghIndicator", IndicatorCD.factory())
  .downgradeComponent("metricProgressBar", { component: MetricProgressBarComponent, propagateDigest: false })
  .downgradeComponent("peopleSelector", { component: PeopleSelectorComponent, propagateDigest: false })
  .downgradeComponent("favoriteButton", { component: FavoriteButtonComponent, propagateDigest: false })
  .downgradeComponent("userDetails", { component: UserDetailsComponent, propagateDigest: false })
  .downgradeComponent("onboarding", { component: OnboardingComponent, propagateDigest: false })
  .downgradeComponent("uiAlert", { component: UiAlertComponent, propagateDigest: false })
  .downgradeComponent("uiLabel", { component: UiLabelComponent, propagateDigest: false })
  .downgradeComponent("uiCard", { component: UiCardComponent, propagateDigest: false })
  .downgradeComponent("ghErrorNotification", { component: ErrorNotificationComponent, propagateDigest: false })
  .downgradeComponent("createOkrDropdownButton", { component: CreateOkrDropdownButtonComponent, propagateDigest: false })
  .downgradeComponent("datePickerLegacy", { component: DatePickerLegacyComponent, propagateDigest: false })
  .downgradeComponent("featureBanner", { component: FeatureBannerComponent, propagateDigest: false })
  .downgradeComponent("itemAutomation", { component: ItemAutomationComponent, propagateDigest: false })
  .downgradeComponent("saveFilterChanges", { component: SaveFilterChangesComponent, propagateDigest: false })
  .downgradeComponent("dateView", { component: DateViewComponent, propagateDigest: false })
  .downgradeComponent("emptyState", { component: EmptyStateViewComponent, propagateDigest: false })
  .downgradeComponent("emptyStateInfoBoxes", { component: EmptyStateViewInfoBoxesComponent, propagateDigest: false })
  .downgradeComponent("featuredInsightboards", { component: FeaturedInsightboardsComponent, propagateDigest: false })
  .downgradeComponent("platformRedirect", { component: PlatformRedirectComponent, propagateDigest: false })
  .service("TenorGifService", TenorGifService)
  .downgradeInjectable("ReactionsFacade", ReactionsFacade)
  .downgradeInjectable("CommentsFacade", CommentsFacade)
  .downgradeInjectable("SessionSelectorUtilsService", SessionSelectorUtilsService)
  .downgradeInjectable("ShareableFiltersFacade", ShareableFiltersFacade)
  .downgradeInjectable("ShareableFiltersModalsService", ShareableFiltersModalsService)
  // automation selector
  .downgradeComponent("itemAutomationSelector", { component: ItemAutomationSelectorComponent, propagateDigest: false })
  .downgradeInjectable("SupportedItemAutomationTypeService", SupportedItemAutomationTypeService)
  .downgradeInjectable("SupportedItemAutomationSuggestionTypeService", SupportedItemAutomationSuggestionTypeService);

mod.downgradeComponent("uiValidationHint", { component: UiValidationHintComponent });
mod.downgradeComponent("inputSearch", { component: InputSearchComponent });
mod.directive("ghCompile", GhCompileDirective.factory());
mod.directive("marketplaceLink", MarketplaceLinkDirective.factory());
mod.directive("esc", KeydownDirective.factoryEsc());
mod.directive("enter", KeydownDirective.factoryEnter());
mod.directive("tabKey", KeydownDirective.factoryTab());
mod.directive("cmdEnterSubmit", CmdEnterSubmitDirective.factory());
mod.directive("gtmhubDropdown", GtmhubDropdown.factory());
mod.directive("gtmhubDropdownItem", GtmhubDropdownItem.factory());
ArraySelectionDirective.register(mod);
mod.directive("toggleDropdown", ToggleDropdown.factory());
mod.directive("dropdownKeys", DropdownKeys.factory());
mod.directive("focusOn", FocusOn.factory());
mod.directive("focusMe", FocusMe.factory());
mod.directive("clickOnFirstInput", ClickOnFirstInput.factory());
mod.directive("rerenderDetector", RerenderDetectorDirective.factory());
mod.component("welcomeModalV1", WelcomeModalV1Component);
MenuDirective.register(mod);
MenuItemDirective.register(mod);

mod.controller("FilterFormCtrl", FilterFormCtrl);
mod.controller("DateRangePickerCtrl", DateRangePickerCtrl);
mod.controller("UnitFormattingFormCtrl", UnitFormattingFormCtrl);

mod.downgradeComponent("ghAssigneeAvatarGroup", { component: AssigneeAvatarGroupComponent, propagateDigest: false });
mod.downgradeComponent("ghBadgeAvatar", { component: BadgeAvatarComponent, propagateDigest: false });
mod.downgradeComponent("ghPagination", { component: PaginationComponent, propagateDigest: false });
mod.downgradeInjectable("PermissionsFacade", PermissionsFacade);

export default mod.name;
